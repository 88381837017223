.privacy,.conditions{
    display:inline-block;
    margin: 2.5em 0 0 0;
    padding: 0 0 2.5em 0;
    width: 100%;
    min-height:calc(100vh - 2.5em);
    background-color: skyblue;
    
}

.privacy-banner{
    position: relative;
    margin: 0 0 2em 0;
    height: calc(100vh - 2.5em);
    width: 100%;
    background-color: #fff;
}
.privacy-banner-wrapper{
    position: absolute;
    display: block;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
}
.privacy-banner-image{
    width: 4em;
    margin: 0 0 1em 0;
}
.privacy-banner-text{
    display:block;
    opacity:0;
    margin: 0 auto 0 auto;
    animation: textAppear 2s 1 ease-out forwards;
    animation-delay: 7s;
    color:#595959;
}
.privacy-wrapper,.conditions-wrapper{
    max-width: 90%;
    margin: 0 auto 0 auto;
    background-color: skyblue;
    padding: 0 0 1em 0;
    border-radius: 0.3em;
}

.privacy-subwrapper, .conditions-subwrapper{
    background-color: #fff;
    padding: 1em 0 1em 0;
    border-radius: 0.3em;
}

.privacy-title,.conditions-title{
    margin: 0 0 0 0.5em;
    color:#fff;
}
.privacy-index-title, .conditions-index-title{
    margin: 3em 0 0 0.6em;
}
.privacy-date,.conditions-date{
    color: #fff;
    margin: 1em 0 2.5em 1em;
    
}

.privacy-text,.conditions-text{
    text-align: justify;
    color:#595959;
    margin: 1em 1em 0 1em;
    background-color:#fff;
}

.privacy-text b,.conditions-text b{
    color:#595959;
}
.privacy-text u b,.conditions-text u b{
    color:#595959;
}

.privacy-text u, .conditions-text u{
    text-decoration: underline;
    color:#595959;
}
.privacy-links,.conditions-links{
    color: #85b4cc;
    cursor: pointer;
}
.privacy-links:hover,.conditions-links:hover{
    color: red;
}
.privacy-index-list,.conditions-index-list{
    display:inline-block;
    list-style: none;
}
.conditions-index-list-numbered{
    display:inline-block;
    margin: 0 0 0 2.5em;
}
.privacy-index-list a,.conditions-index-list a{
    display: inline-block;
    margin: 1em 0 0 1em;
    color: #66afe0;
    cursor:pointer;

}
.privacy-index-list a:hover, .conditions-index-list a:hover{
    color: red;
}
.anchor{
    padding-top:2.5em;
}

.privacy-item-title, .conditions-item-title{
    margin: 0 0 0 1em;
}

.privacy-item-subtitle, .conditions-item-subtitle{
    margin: 1.5em 0 0.5em 1em;
}

.privacy-list, .conditions-list{

}
.privacy-list li, .conditions-list li{
    list-style-type: '■';
    display: list-item;
    margin: 0 0 0 1.5em;
}
.conditions-list-bar li{
    list-style-type: '-';
    display: list-item;
    margin: 0 0 0 2em;
}

.italic{
    font-style: italic;
}

.privacy-list-item, .conditions-list-item{
    display: inline;
    width:1em;
    color:#595959;
}

.privacy-list-text, .conditions-list-text{
    display: list-item;
    padding:0 0 0 0.5em;
    width: 90%;
    text-align: justify;
    color:#595959;
    margin: 1em 0 0 0;
}
.privacy-list-text b, .conditions-list-text b{
    color:#595959;
}
.conditions-index-list-item{
    color:#595959;
    line-height: 2em;
}


.conditions-list .extra-margin-list{
    margin: 1em 0em 0 3em;
}

@keyframes textAppear{
    100%{
        opacity: 1;
    }
}