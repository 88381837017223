* {
margin:0;
padding:0;
border: none;
text-decoration: none;
color: initial;
}
*::selection{
    background-color: #66afe0;
    color: #fff;
}
::-webkit-scrollbar{
    width: 0.5em;
    margin:0;
    padding:0;
}
::-webkit-scrollbar-track{
    width:0;
}
::-webkit-scrollbar-thumb{
    background-color:#d9d9d9d9;
    border-radius: 0.3em;
    margin:0;
    padding:0;
}
html{
    min-height: 100%;
    box-sizing: border-box;
    position: relative;
}
body{
    min-height: 100vh;
    width: 100%;
}

#root{
    height:100%;
    width:100%;
}