.button{
    /*position: absolute;*/
    display:inline-block;
    padding: 0.4em 1em 0.5em 1em;
    font-size: calc(1em + 2vw);
    /*bottom:10vh;
    left:50%;
    transform: translate(-50%,-50%);*/
    /*border: 0.06625em solid #fff;
    background-color: transparent;
    color: #fff;
    border-radius: 0.1em;*/
    cursor: pointer;
    margin:1em auto 1em auto;
    width: 7em;
}

.buttonOff{
    display: none;
    position: absolute;
    padding: 0.4em 1em 0.5em 1em;
    font-size: calc(.5em + 2vw);
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border: 0.06625em solid #fff;
    background-color: transparent;
    color: #fff;
    border-radius: 0.1em;
    cursor: pointer;
    margin:0;
}
.buttonOn{
    display:inline-block;
    opacity:0;
    animation: display 2s;
    animation-fill-mode: forwards;
} 
@keyframes display{
from {opacity:0;}
to{opacity:100;}
}

@media screen and (max-width: 400px){
    .button{

    }
}