.checkout{
    min-height: 100vh;
    width: 100%;
    display:block;
}

.checkout-wrapper-left{
    display:block;
    float:left;
    width:50%;
    height:100vh;
    background: linear-gradient(0.6turn, #866dff,#360da9ff);
    text-align: center;

}

.checkout-left-title{
    color: #fff;
}
.checkout-summary{
    display: block;
    text-align:left;
    max-width: 50%;
    margin: 0 auto 0 1em;
}

.checkout-summary-list{
    list-style: none; 
}
.checkout-summary-list-items{
    color: #fff;
    /*color: #00abff; */
}

.checkout-price{
    color: lime; 
}
.checkout-wrapper-right{
    position:relative;
    float:right;
    width:50%;
    height: 100vh;
    background-color: #fff;

}

.checkout-wrapper-right-title{
    display:block;
    text-align: center;
    margin: 1em;
    align-self: top;
}
.checkout-wrapper-inner-right{
    position:relative;
    display: flex;
    align-items: center;
    padding: 2em;
}
.checkout-wrapper-right-form{
    display: block;
    width: 80%;
    height: auto;
    margin: 0 auto 0 auto;
}


.checkout-form-card{
    position: relative;
    display: block;
    width: 15em;
    height: 8em;
    background: linear-gradient(0.4turn, #866dff,#360da9ff);
    /*background-color: blue;*/
    margin: 0 auto 0 auto;
    padding: 1em;
    border-radius: 0.4em;
    box-shadow: 0em 0em 0.3em;
}

.checkout-form-input{
    display: block;
    width: 90%;
     -webkit-appearance: none;
     -moz-appearance: textfield;
     padding: 0.5em;
     margin: 0.5em auto 0 auto;
     border-radius: 0.3em;
     background-color: rgba(255,255,255,0.2);
     color: #fff;
     font-weight: bold;
}

.checkout-form-hidden-input{
    display:none;
}

.checkout-form-input::placeholder{
    font-weight: normal;
}

.checkout-form-input-ccv{
    float: left;
    width: 3em;
    margin: 0.5em 0 0 0.3em;
}
.checkout-form-cards-imgs{
    display: block;
    width: 5em;
    max-height: 3em;
    margin: 0.5em;
    float: right;
}

.checkout-form-price-wrapper{
    position: relative;
    display:inline-block;
    
}
.checkout-form-price-centering{
    position:relative;
    display: flex;
    width: 18.5em;
    margin: 0 auto 0 auto;
    align-items:center;
}
.checkout-wrapper-right-form input[type="radio"]{
    /*position:relative;*/
    display:block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #d1d1d1;
    border: solid 0.2em #6040d6;
    border-radius: 0.4em;
    width: 10em;
    height: 8em;
    margin: 0.5em;

}

.checkout-wrapper-right-form input[type="radio"]:hover{
    background-color: #856cfe;
    cursor: pointer;
}
.checkout-wrapper-right-form input[type="radio"]:checked{
    background-color: #6040d6;
    color:#fff;
    border-color:#6040d6;
}
.checkout-form-price-input-label{
    position: absolute;
    text-align: center;
    top:50%;
    left: 50%;
    transform:translate(-50%,-50%);
    color:#fff;
    font-weight: bold;
    cursor: pointer;
    
}


.checkout-links{
    display:block;
    position: absolute;
    width: 20%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 1em;
    margin: 0 auto 0 auto;
    background-color:#6040d6;
    color: #fff;
    text-align: center;
    font-weight: 800;
    border-radius: 0.5em 0.5em 0 0 ;
    
}

/*.checkout-links:hover{
    color: #fff;
    background-color: #81ea81;
}*/

.checkout-form-button{
    display:block;
    padding: 1em;
    margin: 1em auto 0 auto;
    background-color:#66afe0;
    color: #fff;
    text-align: center;
    font-weight: 800;
}

.checkout-form-button:hover{
    cursor:pointer;
    background-color: #fff ;
    color: #66afe0;
    outline: solid 0.1em #66afe0;
}
.checkout-summary-title{
    color: #fff;
    margin: 0.5em;
}
.checkout-form-outside-input{
    display: block;
    margin: 0 auto 1em auto;
    padding: 0.8em;
    width: 15em;
    /*background-color: #d1d1d1;*/
    border: solid 0.1em #6040d6;
    border-radius: 0.3em;
    color: #6040d6;
    font-weight: bold;

}
.checkout-form-outside-input::placeholder{
    color: #6040d6;
}

#required-wrapper{
    position: absolute;
    display:flex;
    margin: 0 auto 0 auto;
    bottom:0;
    left: 50%;
    transform: translateX(-50%);
    background-color: red;
    max-width: 80%;
    height: 5em;
    text-align:center;
    z-index: 3;
    
}
.required-text{
    font-weight: bold;
}
/*#checkout-submit{
    background-color: #6040d6;
}*/
.checkout-wrapper-right #checkout-submit :hover{
    background-color: #81ea81;
}
.checkout-links:hover{
    background-color: #81ea81;
}
.checkout-submit-red{
    background-color: red;
}
.checkout-submit-red:hover{
    background-color: red;
}

@media screen and (max-width:650px){
    .checkout-wrapper-left{
        display:inline-block;
        margin: 0;
        padding: 0;
        width:100%;
        height:50vh;
        overflow-y: scroll;
    }
    .checkout-wrapper-right{
        display:inline-block;
        margin: 0;
        padding: 0 0 11em 0;
        width:100%;
        height: initial;
    }
    .checkout-links{
        position:absolute;
        bottom: 0;
        display:block;
        border-radius: 0.5em 0.5em 0 0;
        width: 50%;
        padding: 0.6em;
        margin:0;
    }
    
}

