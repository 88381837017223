.offers{
    position:relative;
    height:calc(100vh - 2em);
    text-align:center;
    margin:0;
    overflow:hidden;
    background: url("../Images/offersBackground.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    /*filter: blur(0.2em);*/
    /*background-color:#333333;*/
    
}
/*.offers-overlay{
    position:absolute;
    top:0;
    width:100%;
    height:100%;
    background-color: rgb(0,0,0);
    opacity:0.7;
    z-index:-1;  
}*/
.offers-background{
    position:absolute;
    top:0;
    height:calc(100vh - 2em);
    width:100%; 
    background: url("../Images/offersBackground.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /*filter: blur(0.2em);*/
    z-index:1;
    
    /*filter:blur(0.2em);*/


}
.offers-text{
    /*position:absolute;*/
    position:absolute;
    display:block;
    max-height: 100%;
    width:80%;
    top: 50%;
    left: 50%;
    transform:translate(-50%, -50%);
    /*margin: 0 auto 0 auto;*/
    padding: 0 0 1em 0;
    
    /*top:20%;
    transform:translateY(-20%);*/
    border-radius: 0.5em;
    text-align: center;
    z-index:2 !important;
    /*background-color: rgba(255,255,255, 0.5);*/
    background-color: rgba(255,255,255, 0.7);
}


.offers-title{
    display:block;
    color: #00bfa5;
    z-index:2;
    margin: 5vh 0 0 0;
    font-weight:bold;
    font-size: calc(0.6em + 2.5vw);
}
.offers-title sub{
    color: #00bfa5;
    margin: none;
    font-size: calc(0.2em + 0.5vw);
}
.offers-subtitle{
    color: #00bfa5;
    margin-top:0;
    z-index:2;
    font-weight:initial;
    font-size: calc(0.6em + .5vw);
}
.offers-subtitle-btn{
    display: inline-block;
    background-color: #00bfa5;
    color:#FFF;
    margin: 1em auto;
    padding: 1em;
    border-radius: 0.2em;
    line-height: 2em;
    cursor: pointer;
    font-weight: bold;
}
.offers-subtitle-btn:hover{
    background-color:#009c87;
    cursor: pointer;
}

.offers-endStatement{
    color:#00bfa5;
    font-weight:initial;
    margin: 0.5em 0 0 0;
    text-shadow: -0.0125em -0.0125em 0 #000,
    0.0125em -0.0125em 0 #000,
    -0.0125em 0.0125em 0 #000,
    0.0125em 0.0125em 0 #000;
}

.offers-cursive{
    font-style: italic;
}

.offers-bold{
    font-weight: bold;
}