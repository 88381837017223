.faq{
    margin:0;
    padding-bottom:2em;
    min-height: 100vh;
    width: 100%;
    text-align: center;
   background: linear-gradient(to right, #4b525a, #6a6f79, #575d66);
   /*background-color: #f3fafd;*/

}
.faq-banner{
    position:relative;
   
}
.faq-banner-image{
    margin: 2.5em 0 0 0;
    width:100%;
     
}

.faq-banner-title{
    position: absolute;
    top:50%;
    transform:translateY(-50%);
    color: #fff;
    font-size: 4em;
    width:100%;

}

.faq-question{
    color: #fff;
    margin: 1.5em auto auto;
    border-top: solid 0.0625em #fff;
    border-bottom: solid 0.0625em #fff;
    max-width: 80%;
    background-color: #fff;
    box-shadow: rgba(0,0,0, 0.3) 0em 0.4em 1em -0.2em;
    padding: 0.5em 1em;
    border-radius: 0.3em;
}

.faq-question-title{
    color: grey;
    margin: 0.5em 0 0.5em 0;
}

.faq-question-text{
    color: #000;
    margin: 0.5em 0 0.5em 0;
}

.faq-question-link{
    /*color: #ff6666;*/
    color: #5bc7ff;
}

.faq-question-link:hover{
    color: #ff6666;
    /*color: #A7E1FF;*/
}
