.whatsapp{
    position: fixed;
    display: block;
    width: 8.1em;
    height: 1.5em;
    bottom: 2.5em;
    left: 1.5em;
    z-index: 7;
    margin: 0 0 1em 0;
    /*border-radius: 0.2em;
    color:#fff;
    background-color: #00BFA5;
    padding: 0.4em 0.6em;
    line-height: 1.5em;*/
    
}
.whatsapp-button{
    display:block;
    width: 6.9em;
    height: 1.5em;
    border-radius: 0.2em;
    color:#fff;
    background-color: #00BFA5;
    padding: 0.4em 0.6em;
    line-height: 1.5em;

    /*display: block;
    float: right;
    height: 1.5em;
    width: 5em;
    line-height: 1.5em;
    color: #fff;
    text-align: center;*/
}

.whatsapp-text{
    display: block;
    float:right;
    color:#fff;
}
.whatsapp-img{
    display:inline-block;
    height:1.5em;
    width: 1.5em;
    line-height: 1.5em;
    color: #fff;
}

@media screen and (max-width: 500px){
    .whatsapp{
        width: 1.5em;
        height: 1.5em;
    }
    .whatsapp-button{
        width: 1.5em;
    }
    .whatsapp-text{
    display: none;
    }
}