@media screen and (max-width: 650px){
    .Banner .Banner-top{
        display:block;
    }

    .Banner .Banner-top-image{
        margin: 2.5em auto;
        width: 100%;
        height: 80vh;
    }

    .Banner .Banner-top-text{
        display: inline;
        height: 50vh;
     /*line-height:calc(15vh - 1em);*/
     margin: 5% auto 0 auto;
    }
    .Banner-top-text h1 {
        margin: 2em auto 0 auto;
        width: 80%;
        z-index:0;
        font-size: calc(1.5em + 0.5vw);
    }
    /*.Banner-bottom-text h1{
    z-index:0;
    font-size: calc(1.5em + 0.5vw);
    color: #fff;
        
    }*/
}
.Banner{
    min-height: calc(50vh - 2em);
    height: 100%;
    background:url("../Images/dataBackground.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000;

}
/* OLD Banner Top
.Banner-top{
    width: 100%;
    height:calc(50vh);
    overflow:hidden;

}
*/

.Banner-top{
    display: flex;
    width: 100%;
    min-height:calc(100vh);
    overflow:hidden;
    position: relative;
    align-content: center;
    align-items: center;

}

.Banner-top-image{
    display:block;
    width: 50%;
    height: 80vh;
    float: right;
    margin: 0 0 0 0;
    /*background-color: red;
    background: url("../Images/iphone.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;*/
    
}
.Banner-bottom{
    position:relative;
    width: 100%;
    height:calc(50vh);
    /*background: rgb(30,30,30);*/
    overflow:hidden;
    z-index:0;
}

.Banner-top-text {
     display: block;
     width: 50%;
     height: 100vh;
     line-height:calc(15vh - 1em);
     margin-left: 2%;
     margin-top: 5%;
     font-weight: bold;
     z-index:1;
     
}

.Banner-bottom-text {
     
     display: inline-block;
    line-height:calc(50vh - 1em);
     float: right;
     margin-right: 2%;
     font-weight: bold;
     z-index:9;
     mix-blend-mode: difference;
}
.Banner-top-text h1 {
    z-index:0;
    font-size: calc(1em + 5vw);
    line-height: 1em;
    color: #fff;
}

.Banner-top-text h2 {
    margin:0;
    line-height: 0em;
    z-index:0;
    font-size: calc(1em + 2vw);
    color: #00bfa5;
}

.Banner-bottom-text h1{
z-index:0;
font-size: calc(1.5em + 5vw);
color: #fff;
    
}
svg{
    display: block;
    width:100%;
    height: 100%;
}
		

.Fade{
    animation: fade 1s;
    animation-fill-mode: forwards;
}
@keyframes fade{
    from{opacity:100;}
    to{opacity: 0;}
}