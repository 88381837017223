.VIB{
    position: relative;
    display: inline-block;
    width:100%;
    min-height:100vh;
    text-align:center;
    /*overflow: hidden;*/
}

.VIB-title{
    font-size:calc(0.6em + 1vw);
}

.VIB-subtitle{
    font-size:calc(0.6em + 0.5vw);

}

.VIB-container{
    display: flex;
    width: 100%;
    min-height: 100vh;
    /*background-color:red;*/
    margin: 5% 0 0 0;
    align-content:center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
}

.VIB-plan{ /*Mobile First*/
    position:relative;
    display: inline-block;
    max-width:23.78em;
    width:100%;
    /*height: 50em;*/
    min-height: 40em;
    background-color: #fbfbfb;
    border: 0.2em solid #f9f9f9;
    margin: 5em auto;
    border-radius: 0.2em;
    

}
.VIB-plan:hover{
    animation: borderColors 1s linear 1;
    animation-fill-mode: forwards;
}

.VIB-plan:hover .VIB-plan-image{
    animation: borderColorMedal 1s linear 1;
    animation-fill-mode: forwards;
}

.VIB-plan-image{
    position: absolute;
    top:0;
    left:50%;
    display:block;
    max-width:8em;
    max-height: 8em;
    width:100%;
    height:100%;
    /*background-image:url("https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQiW9pX1DkUBapFUSfOKxU3dgA481sUfSkaWV8VdzGJj8Uxcpui");
    */border-radius:50%;
    border: 0.15em solid #D1D1D1;
    transform: translate(-50%,-50%);
}
.VIB-plan-background-orange{
    background-color: orange;
}
.VIB-plan-background-silver{
    background-color: lightgrey;
}
.VIB-plan-background-green{
    background-color: lightgreen;
}
.VIB-plan-medal-text{
    display:inline;
    font-size:3em;
    color: #FFF;
    vertical-align:middle;
    line-height: 2.5em;
}

.VIB-plan-content{
    position:relative;
    display:inline-block;
    width:80%;
    height:60%;
    margin: 6em auto 2em auto;
    /*border: 0.15em solid #D1D1D1;*/ 
    border-radius: 0.1em;
}
.VIB-plan-description{
    display:inline-block;
    list-style-type: none;
    width: 100%;
    height:70%;
    font-weight: 600;
    margin: 1em 0 2em 0;
}
.VIB-plan-description-item{
    /*color:#00d4fa;*/
    color: #000;
   
}

.VIB-plan-disclaimer{
    position:absolute;
     display:block;
     width:100%;
     bottom:0;
     color:#D1D1D1;
     
}

.VIB-plan-button{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    display: block;
    text-align:center;
    width: 50%;
    background-color: yellow;
    font-size: initial;
    cursor: pointer;
    /*margin: 20% auto 0 auto;*/
    margin:0 auto;
    padding: 1em 0 1em 0;
    font-weight: bold;
    border-radius: 0.15em;
}
.Orange{
    color: orange !important;
}
.Silver{
    color: grey;
}
.Plus{
    font-weight: bold;
}
.VIB-plan-description-hosting{
    margin: 2em 0 0 0;
    color:#6747c7;
}
.Hostinger{
    width:50%;
}

@keyframes borderColors{
    100%{border-color: #7ff0c3;}
}

@keyframes borderColorMedal{
    100%{
        border-color: #7ff0c3 #7ff0c3 #7ff0c3 #7ff0c3;
    
    }
}

@media screen and (min-width: 750px){
    .VIB-plan{
        margin: 5em 5% 5em 0;
    }
    .last{
    margin:5em 0 5em 0;
    }
}



