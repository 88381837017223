.foot{
    position:relative;
    height: 100%;
    text-align:center;
}
.foot-text{
    display:inline-block;
    margin: 2em auto 1em auto;
    color:#000;
}
.foot-social{
    display:block;
    position:relative;
    max-width:16em;
    width:auto;
    height:3.7em;
    margin: 1em auto 5em auto;
}

.Face{
    display:block;
    float:left;
    width:1.5em;
    height:1.5em;
    margin: 0 0 0 0;
    border-radius: 0.2em;
    padding: 1em;
    line-height:1.5em;
    background-image: url("../Images/Facebook.svg");
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #1877f2;
    cursor:pointer;
}
 .Insta, .In, .Twit{
    display:block;
    float:left;
    width:1.5em;
    height:1.5em;
    margin: 0 auto 0 0.5em;
    /*border: 0.0625em solid #D1D1D1;*/
    border-radius: 0.2em;
    padding: 1em;
    line-height:1.5em;
    cursor:pointer;
}
.Insta{/*IMAGE*/
    background-image: url("../Images/Instagram.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.In{/*IMAGE*/
    background-image: url("../Images/LinkedIn.svg");
    background-color:#0a66c2;
    background-size:70%;
    background-repeat: no-repeat;
    background-position: 0.7em 50%;
}
.Twit{/*IMAGE*/
    background-color:#1da1f2;
    background-image: url("../Images/Twitter.svg");
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
}
/* CREDIT CARDS START */
.creditcards-wrapper{
    margin: 0 auto 3em auto;
    width:100%;
    min-height: 10em;
    margin: 0 0 0 0;
}
.creditcards-wrapper p{
    display:inline-block;
    width:100%;
    color:#000;

}

.creditcards-group{
    position:relative;
    margin: 1em 0 0 0;
    min-height: 4em;
}
.visa{
    display: inline-block;
    position:relative;
    height:4em;
    width:6em;
    background-color: #15195A;
    border-radius: 0.3em;
    
}
.visa-pic{
    position: absolute;
    width: 80%;
    top: 50%;
    left:50%;
    transform:translate(-50%,-50%);
}

.amex,.master{
    position:relative;
    display: inline-block;
    height:4em;
    width:4em;
    overflow: hidden;
    margin: 0 0 0 0.5em;
}

.amex-pic{
    width:4em;
    border-radius: 0.2em;
}

.master-pic{
    position:absolute;
    width: 100%;
    bottom:0;
    left:0;
}

.discover{
    display: inline-block;
    position:relative;
    height:4em;
    width:6em;
    border: solid 0.15em #000;
    border-radius: 0.3em;
    overflow:hidden;
    margin: 0 0 0 0.5em;
}
.discover-pic{
    height: 4em;

}
/* CREDIT CARDS END */

.foot-logo{
    display: block;
    width:15em;
    height: 5em;
    margin:0 auto 0 auto;
}

.foot-link-wrap{
    display:block;
    width:100%;
    height:1.5em;
    padding: 0 0 0.5em 0;
}

.foot-copy{
    display:inline-block;
    margin: 0 0 0 0; 
}
.foot-copy > p{
  display:inline-block; 
}
.foot-copy #date,p{
    color:#D1D1D1;
}

.foot-links{
    display:inline-block;
    margin: 0 calc(0.2em + 1vw) 0 calc(0.2em + 1vw);
    color:#D1D1D1;
}
.foot-links:hover{
    color: #A7E1FF;
}
.foot-bottom-left{
    display:block;
    float:left;
    margin: 0 0 0 0;
    
}
.foot-bottom-left > p{
    display:inline-block;
}
.foot-bottom-right{
    display:block;
    float:right;
    margin: 0 0 0 0;
}
@media screen and (max-width:500px){
    .foot-links{
        display: block;
    }
    .foot-bottom-right{
        float:initial;
    }
    .foot-bottom-left{
        display:block;
        float:initial;
    }
    .foot-copy{
        display: inline-block;
        margin:0 0 0.8em 0;

    }
    .foot-logo{
        width:calc(6em + 30vw);
    }

}
