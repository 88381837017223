.contacts{
    position:relative;
    display:flex;
    padding: 2.5em 0 0 0;
    height:calc(100vh - 2.5em);
    width:100%;
    background-color: #fff;
    
}
.contacts-left{
    position:relative;
    display: block;
    width:50%;
    height:100%;
    overflow:hidden;
    background-image: url("../Images/contacts-men.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.contacts-right{
    position:relative;
    display:block;
    width:50%;
    height:100%;
}
.contacts-form-outsider-wrap{
}
.contacts-form{
    position: absolute;
    top:50%;
    left:50%;
    width: 25.7em;
    height:35.75em;
    /*background-color:#6a6f79;*/
    /*background-color: #fff;*/
    transform: translate(-50%,-50%);
    /*animation: showForm 2s 1 forwards ease-out;
    animation-delay: 2s;*/
    font-family: 'Poppins', sans-serif;
    text-align: center;
    z-index:3;
}
.contacts-form-wrapper{
    position: relative;
    display: inline-block;
    width:100%;
    height:100%;
}
.contacts-form-radioGroup{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 1em auto 2em auto;
}

.contacts-form-labelAndBtn{
    position: relative;
    display: inline-block;
}
.labelAndBtn_first{
    margin: 0 0 0 1em;
}

.labelAndBtn_last{
    margin: 0 1em 0 0;

}
.contacts-form-radioGroup input[type="radio"]{
    display: block;
    border-radius: 20%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 1.5em;
    height:1.5em;
    margin: 0 auto 0 auto;
    border: solid 0.2em #d1d1d1;
    background-color: #fff;
    
}
.contacts-form-radioGroup input[type="radio"]:hover{
    border-color: #7BAEE8;
    cursor: pointer;
}
.contacts-form-radioGroup input[type="radio"]:checked{
    animation: pushedBtn 500ms 1 forwards ease-out;
}

.contacts-radioBtn{
    display:inline-block;
    margin: 0 auto 0 auto;
}
.contacts-radioBtn-text{
    /*color:#fff;*/
    color: #bbb2b2;
    display: flex;
}
.contacts-radioBtn-text:hover{
    cursor: pointer;
}
.contacts-form-title{
    margin: 1em 0 2em 0;
    /*color:#fff;*/
    color: #bbb2b2;
    font-weight: 500;
}

.contacts-form-textInputs{
    width: 70%;
    padding: 1em;
    border: solid 0.0625em #d1d1d1;
    border-radius: 0.2em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    color:#707070;
}

.contacts-form-textArea{
    width: 70%;
    height: 8em;
    margin: 1.5em 0 3em 0;
    padding: 1em;
    border: solid 0.0625em #d1d1d1;
    border-radius: 0.2em;
    resize: none;
    font-family: 'Poppins', sans-serif;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color:#707070;
}

.contacts-form-submitBtn{
    display: block;
    margin: 0 auto 0 auto;
    width: 70%;
    height: 2.5em;
    background-color: #7BAEE8;
    border-radius: 0.2em;
    color: #fff;
    border: solid 0.1em #7BAEE8;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5em;
    line-height: 2.4em;
    font-weight: bold;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.contacts-form-submitBtn:hover{
    background-color: #fff;
    color: #7BAEE8;
    border-color: #7BAEE8;
    cursor: pointer;
}
#other-info{
    display: none;
    color: #7BAEE8;
    text-align:left;
}
#other-info .contacts-phoneLine,#other-info .contacts-emailLine{
    line-height:2em;
    height:2em;
}
#other-info .address img, #other-info .contacts-phoneLine img,#other-info .contacts-emailLine img {
    display:block;
    color: #7BAEE8;
    height: 2em;
    width:2em;
    float:left;
}

#other-info p{
    display: inline;
    color: #7BAEE8;
    height: 2em;
    line-height: 2em;
    margin: 0.5em 0 0 0.5em;
}

#other-info b{
    color: #7BAEE8;
}
#other-info .address{
    display: block;
    margin: 0 0 0.5em 0;
    line-height: 2em;
}



@keyframes pushedBtn{
    100%{
        border: solid 0.8em #7BAEE8;
    }
}

@keyframes showForm{
    100%{ opacity: 100%;}
}

@media screen and (max-width: 830px){
    .contacts-left{
        width: 100%;
    }
    .contacts-right{
        position: absolute;
        width:100%;
    }
    .contacts-form{
        width: 100%;
    }
    input[type="radio"]{
        background-color: rgba(255,255,255,0.8);
    }
    .contacts-form-textInputs, .contacts-form-textArea{
        background-color: rgba(255,255,255, 0.8);
    }

    .contacts-right .contacts-form-submitBtn{
        opacity:1;
    }
    #other-info{
        color:#fff;
        background-color: rgba(127,127,127,0.5);
        border-radius: 0.2em;
        margin: 1em;
        padding: 0.5em 0 0.5em 0;
    }
    #other-info img{
        color: #fff;
        filter: contrast() brightness(1.5);
    }
    #other-info p{
        color:#fff;
    }
    #other-info b{
        color:#fff;
        
    }
    #other-info .address, .contacts-phoneLine, .contacts-emailLine{
        margin: 0 0 0 1em;
        color:#fff;
    }
    
    .contacts-form-outsider-wrap{


    }
    
}