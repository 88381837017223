.Skype{
    position: fixed;
    display: block;
    bottom: 2.5em;
    left: 1.5em;
    z-index: 7;
    border-radius: 0.2em;
    overflow: hidden;
    color:#fff;
}
.skype-button{
    width: 5em;
    height: 3em;
}
.Skype .lwc-chat-button{
    background-color: #00aff0;
}
.Skype #chatIconText{
    color: #fff;
}
.lwc-button-icon{
    width:1.8em;
    height:1.8em;
    background-image: url("../Images/skype-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.lwc-button-icon svg{
    display:none;
}

@media screen and (max-width: 500px){
    .Skype{
        width: 2.7em;
        height: 2em;
    }
    .lwc-chat-button{
        height: 2em;
    }
    .Skype #chatIconText{
        display:none;
    }
}