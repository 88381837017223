.testimonials{
    position:relative;
    display:block;
    height:50vh;
    width:100%;
    padding:0;
    margin:0;
}
#LeftArrow{
    position: absolute;
    top:50%;
    left:5%;
    transform: translateY(-50%);
     z-index: 6;
     cursor: pointer;
     padding: 2em;
    
}
.arrow{
    border: solid #000;
    border-width: 0 0.0625em 0.0625em 0 ;
    display:inline-block;
    padding:0.45em;
    border-color: #fff;
   
}
.left{
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.right{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}


#RightArrow{
    position: absolute;
    top:50%;
    right:5%;
    transform: translateY(-50%);
    z-index: 5;
    cursor: pointer;
    padding: 2em;
}

#LeftArrow:hover,#RightArrow:hover{
    background-color: #66afe0;
    border-radius: 0.2em;
    
    
}
.testimonials-Wrapper1{
    position:relative;
    width:100%;
    height:100%;
    margin:0;
    padding:0;
    z-index: 5;
}
.testimonials-image1{
    display:inline-block;
    float:left;
    background-color:#000;
    width:40%;
    height:50vh;

}
.testimonials-image1-child{
    width:100%;
    height:100%;
    background-image: url("../Images/Henry2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}
.testimonials-review1{
    display:block;
    float: right;
    text-align: center;
    background-color:#000;
    color:#fff;
    width:60%;
    height:100%;
    margin:0;
}

.testimonials-title{
    display:inline-block;
    color:#fff;
    margin: 1em 0 0 0;
}

.testimonials-review{
    display:inline-block;
    margin: 1em 0.8em 0 0.8em;
    color:#fff;
    font-style: italic;
    font-weight: 500;
    font-size: calc(0.4em + 1vw);
}

.testimonials-name{
    color:#fff;
    display:block;
    float:left;
    text-align:left;
    margin: 1.5em 0 0 1em;  
    font-style: italic;
    font-weight:600;
}


.testimonials-Wrapper2{
    position:absolute;
    display:block;
    top:0;
    z-index: 4;
    background-color:#000;
    width:100%;

}

.testimonials-image2{
    display:inline-block;
    float:left;
    background-color:#000;
    width:40%;
    height:50vh;

}
.testimonials-image2-child{
    width:100%;
    height:100%;
    background-image: url("../Images/John2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.testimonials-review2{
    display:block;
    float: right;
    text-align: center;
    background-color:#000;
    color:#fff;
    width:60%;
    height:100%;
    margin:0;
}


.testimonials-Wrapper3{
    position:absolute;
    display:block;
    top:0;
    z-index: 3;
    background-color:#000;
    width:100%;

}

.testimonials-image3{
    display:inline-block;
    float:left;
    background-color:#000;
    width:40%;
    height:50vh;

}
.testimonials-image3-child{
    width:100%;
    height:100%;
    background-image: url("../Images/Roxy2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}
.testimonials-review3{
    display:block;
    float: right;
    text-align: center;
    background-color:#000;
    color:#fff;
    width:60%;
    height:100%;
    margin:0;
}
.slideOutRAni{
    animation: slideOutR 1s 1;
    animation-fill-mode: forward;
}

.slideOutLAni{
    animation: slideOutL 1s 1;
    animation-fill-mode: forward;
}
@keyframes slideOutR{
    98%{
        transform: translateX(98%);
    }
    100%{
        transform: translateX(100%);
        opacity:0;

    }
}

@keyframes slideOutL{
    98%{
        transform: translateX(-98%);
    }
    100%{
        transform: translateX(-100%);
        opacity:0;

    }
}

@media screen and (max-height: 500px) {
    .testimonials-review{
        /*width:97%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: " . . . ";*/
        font-size: calc(0.4em + (1.5vh - 0.01vw));

    }
}

@media screen and (max-width: 500px){
    .testimonials{
        height:calc(100vh - 2em);
    }
    .testimonials-title{
        font-size: calc(1em + 0.5vw);
    }
    div .testimonials-image1{
        position:absolute;
        bottom:0;
        display:block;
        width:100%;
    }
    .testimonials-review1,.testimonials-review2 ,.testimonials-review3{
        top:0;
        width:100%;
        font-size: calc(0.8em + (1.5vh - 0.01vw));
        
    }
    .testimonials-Wrapper2, .testimonials-Wrapper3 {
        position:absolute;
        float:right;
        height:100%;
        top:0%;
    }
    div .testimonials-image2{
        position:absolute;
        display:inline-block;
        bottom:0;
        width:100%;
    }
    div .testimonials-image3{
        position:absolute;
        display:inline-block;
        bottom:0;
         background-position: center top;
        width:100%;
    }
    .testimonials-image3-child{
        background-position: center top;
    }
    .testimonials-review{
        font-size: calc(0.5em + 0.5vw);
    }
    .testimonials-name{
        font-size: calc(0.5em + 0.5vw);
        margin: 0.5em 0 0 1em;  
    }

}