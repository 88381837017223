
#menu_top{
height: 2.5em;
width: 100%;
background-color: #fff;
line-height: 2.5em;
position: fixed;
z-index:100;
}
#menu_top > div{
display: inline-block;
float:right;
margin: 0 1em 0 0;
overflow: hidden;
}
.menu_top-logo{
margin-left: 2em;
width: 5em;
}
.menu_top-ActionBTN{
display: inline-block;
margin: 0 1em 0 0;
padding:0 0.5em 0 0.5em;
line-height: initial;
}
.menu_top-ActionBTN b { 
    color: #00BFA5;
}
.menu_top-ActionBTN b:hover{
    color:#90ee90
}
#menu_side {
display: inline-block;
position: relative;
width: 5em;
/*border-radius:0.2em;*/
padding:0 0 0 0.2em;
cursor: pointer;

}
.menu_side-group{
display: inline-block;
margin:0 0.3em 0 0;

}

#menu_side-topBar,#menu_side-middleBar,#menu_side-bottomBar {
width: 1em;
height: 0.2em;
background-color: #000;
margin: 0.05em 0 0 0;
animation-fill-mode: forwards;


}
#menu_side-topBar{
    transform-origin: 100% 0%;
}
#menu_side-middleBar{
    
}
#menu_side-bottomBar{
    transform-origin: 0% 100%;
}
.menuBtn {
    display:inline-block;
    position:relative;
}

#menu_top #side_menu{
    position:absolute;
    background-color: #afd9ff;
    top:2.5em;
    display: block;
    width: 20em;
    height:calc(100vh - 2.5em);
    margin:0;
    animation-fill-mode: forwards;
    border-radius: 0.5em 0 0 0.5em;

}
.side_Menu-hide{
    right:-20em;
}
.side_Menu-off{
    animation: closeMenu 2s 1 alternate;
    right:0em;
    
}

.side_Menu-on{
    animation: showMenu 2s 1 alternate;
    right:-20em;
}

.side_Menu-list{
    width:100%;
    height: 100%;
    display:block;
    margin: 0em auto 0.5em auto;
    font-weight: 600;
    text-align: center;
}
.login{
    display: block;
    color:#fff;
    padding: 0 0.5em;
    line-height: 2.5em;
    background-color: #00BFA5;
    margin: 0 auto 0 auto;
}
.login:hover{
background-color: #90ee90;
}
.side_Menu-list_Items{
    display: block;
    color:#fff;
    line-height: 2.5em;
}
.side_Menu-list_Img{
    width: 1em;
    margin: 0 0.5em 0 0;

}
.side_Menu-list_Items:hover{
    background-color: #00BFA5;
}


.TopBarAnimationOn{
    animation: TopOpentoClose 1S 1;
    animation-fill-mode: forwards;
}

.MiddleBarAnimationOn{
    Animation: MiddleOpentoClose 1S 1;
    animation-fill-mode: forwards;
}

.bottomBarAnimationOn{
    Animation: BottomOpentoClose 1S 1;
    animation-fill-mode: forwards;
}

@keyframes TopOpentoClose{
   
   100%{
        transform:rotate(45deg) translate(0.1em,0.5em);
        height: 0.1em;
        background-color: red;
    }
}

@keyframes MiddleOpentoClose{
    100%{opacity:0;}
}

@keyframes BottomOpentoClose{
    
    100%{
        transform:rotate(-45deg);
        height: 0.1em;
        background-color: red;
        
    }
}

@keyframes closeMenu{
    0%{

    }

    100%{transform: translateX(100%);}

}

@keyframes showMenu{
    0%{

    }

    100%{transform: translateX(-100%);}

}

@media screen and (max-width: 360px){
    .menu_top-ActionBTN{
        display:none;
    }
    #menu_top #side_menu{
        width: 100%;
        right: -100%;
    }
}

@media screen and (max-width: 200px){
    #menu_side{
        display:none;
    }
}