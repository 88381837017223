@import url(https://fonts.googleapis.com/css?family=Montserrat|Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
margin:0;
padding:0;
border: none;
text-decoration: none;
color: #000;
color: initial;
}
*::selection{
    background-color: #66afe0;
    color: #fff;
}
::-webkit-scrollbar{
    width: 0.5em;
    margin:0;
    padding:0;
}
::-webkit-scrollbar-track{
    width:0;
}
::-webkit-scrollbar-thumb{
    background-color:#d9d9d9d9;
    border-radius: 0.3em;
    margin:0;
    padding:0;
}
html{
    min-height: 100%;
    box-sizing: border-box;
    position: relative;
}
body{
    min-height: 100vh;
    width: 100%;
}

#root{
    height:100%;
    width:100%;
}

#menu_top{
height: 2.5em;
width: 100%;
background-color: #fff;
line-height: 2.5em;
position: fixed;
z-index:100;
}
#menu_top > div{
display: inline-block;
float:right;
margin: 0 1em 0 0;
overflow: hidden;
}
.menu_top-logo{
margin-left: 2em;
width: 5em;
}
.menu_top-ActionBTN{
display: inline-block;
margin: 0 1em 0 0;
padding:0 0.5em 0 0.5em;
line-height: normal;
line-height: initial;
}
.menu_top-ActionBTN b { 
    color: #00BFA5;
}
.menu_top-ActionBTN b:hover{
    color:#90ee90
}
#menu_side {
display: inline-block;
position: relative;
width: 5em;
/*border-radius:0.2em;*/
padding:0 0 0 0.2em;
cursor: pointer;

}
.menu_side-group{
display: inline-block;
margin:0 0.3em 0 0;

}

#menu_side-topBar,#menu_side-middleBar,#menu_side-bottomBar {
width: 1em;
height: 0.2em;
background-color: #000;
margin: 0.05em 0 0 0;
-webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;


}
#menu_side-topBar{
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
}
#menu_side-middleBar{
    
}
#menu_side-bottomBar{
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
}
.menuBtn {
    display:inline-block;
    position:relative;
}

#menu_top #side_menu{
    position:absolute;
    background-color: #afd9ff;
    top:2.5em;
    display: block;
    width: 20em;
    height:calc(100vh - 2.5em);
    margin:0;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    border-radius: 0.5em 0 0 0.5em;

}
.side_Menu-hide{
    right:-20em;
}
.side_Menu-off{
    -webkit-animation: closeMenu 2s 1 alternate;
            animation: closeMenu 2s 1 alternate;
    right:0em;
    
}

.side_Menu-on{
    -webkit-animation: showMenu 2s 1 alternate;
            animation: showMenu 2s 1 alternate;
    right:-20em;
}

.side_Menu-list{
    width:100%;
    height: 100%;
    display:block;
    margin: 0em auto 0.5em auto;
    font-weight: 600;
    text-align: center;
}
.login{
    display: block;
    color:#fff;
    padding: 0 0.5em;
    line-height: 2.5em;
    background-color: #00BFA5;
    margin: 0 auto 0 auto;
}
.login:hover{
background-color: #90ee90;
}
.side_Menu-list_Items{
    display: block;
    color:#fff;
    line-height: 2.5em;
}
.side_Menu-list_Img{
    width: 1em;
    margin: 0 0.5em 0 0;

}
.side_Menu-list_Items:hover{
    background-color: #00BFA5;
}


.TopBarAnimationOn{
    -webkit-animation: TopOpentoClose 1S 1;
            animation: TopOpentoClose 1S 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.MiddleBarAnimationOn{
    Animation: MiddleOpentoClose 1S 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.bottomBarAnimationOn{
    Animation: BottomOpentoClose 1S 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

@-webkit-keyframes TopOpentoClose{
   
   100%{
        -webkit-transform:rotate(45deg) translate(0.1em,0.5em);
                transform:rotate(45deg) translate(0.1em,0.5em);
        height: 0.1em;
        background-color: red;
    }
}

@keyframes TopOpentoClose{
   
   100%{
        -webkit-transform:rotate(45deg) translate(0.1em,0.5em);
                transform:rotate(45deg) translate(0.1em,0.5em);
        height: 0.1em;
        background-color: red;
    }
}

@-webkit-keyframes MiddleOpentoClose{
    100%{opacity:0;}
}

@keyframes MiddleOpentoClose{
    100%{opacity:0;}
}

@-webkit-keyframes BottomOpentoClose{
    
    100%{
        -webkit-transform:rotate(-45deg);
                transform:rotate(-45deg);
        height: 0.1em;
        background-color: red;
        
    }
}

@keyframes BottomOpentoClose{
    
    100%{
        -webkit-transform:rotate(-45deg);
                transform:rotate(-45deg);
        height: 0.1em;
        background-color: red;
        
    }
}

@-webkit-keyframes closeMenu{
    0%{

    }

    100%{-webkit-transform: translateX(100%);transform: translateX(100%);}

}

@keyframes closeMenu{
    0%{

    }

    100%{-webkit-transform: translateX(100%);transform: translateX(100%);}

}

@-webkit-keyframes showMenu{
    0%{

    }

    100%{-webkit-transform: translateX(-100%);transform: translateX(-100%);}

}

@keyframes showMenu{
    0%{

    }

    100%{-webkit-transform: translateX(-100%);transform: translateX(-100%);}

}

@media screen and (max-width: 360px){
    .menu_top-ActionBTN{
        display:none;
    }
    #menu_top #side_menu{
        width: 100%;
        right: -100%;
    }
}

@media screen and (max-width: 200px){
    #menu_side{
        display:none;
    }
}
@media screen and (max-width: 650px){
    .Banner .Banner-top{
        display:block;
    }

    .Banner .Banner-top-image{
        margin: 2.5em auto;
        width: 100%;
        height: 80vh;
    }

    .Banner .Banner-top-text{
        display: inline;
        height: 50vh;
     /*line-height:calc(15vh - 1em);*/
     margin: 5% auto 0 auto;
    }
    .Banner-top-text h1 {
        margin: 2em auto 0 auto;
        width: 80%;
        z-index:0;
        font-size: calc(1.5em + 0.5vw);
    }
    /*.Banner-bottom-text h1{
    z-index:0;
    font-size: calc(1.5em + 0.5vw);
    color: #fff;
        
    }*/
}
.Banner{
    min-height: calc(50vh - 2em);
    height: 100%;
    background:url(/static/media/dataBackground.fbc06a09.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000;

}
/* OLD Banner Top
.Banner-top{
    width: 100%;
    height:calc(50vh);
    overflow:hidden;

}
*/

.Banner-top{
    display: flex;
    width: 100%;
    min-height:calc(100vh);
    overflow:hidden;
    position: relative;
    align-content: center;
    align-items: center;

}

.Banner-top-image{
    display:block;
    width: 50%;
    height: 80vh;
    float: right;
    margin: 0 0 0 0;
    /*background-color: red;
    background: url("../Images/iphone.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;*/
    
}
.Banner-bottom{
    position:relative;
    width: 100%;
    height:calc(50vh);
    /*background: rgb(30,30,30);*/
    overflow:hidden;
    z-index:0;
}

.Banner-top-text {
     display: block;
     width: 50%;
     height: 100vh;
     line-height:calc(15vh - 1em);
     margin-left: 2%;
     margin-top: 5%;
     font-weight: bold;
     z-index:1;
     
}

.Banner-bottom-text {
     
     display: inline-block;
    line-height:calc(50vh - 1em);
     float: right;
     margin-right: 2%;
     font-weight: bold;
     z-index:9;
     mix-blend-mode: difference;
}
.Banner-top-text h1 {
    z-index:0;
    font-size: calc(1em + 5vw);
    line-height: 1em;
    color: #fff;
}

.Banner-top-text h2 {
    margin:0;
    line-height: 0em;
    z-index:0;
    font-size: calc(1em + 2vw);
    color: #00bfa5;
}

.Banner-bottom-text h1{
z-index:0;
font-size: calc(1.5em + 5vw);
color: #fff;
    
}
svg{
    display: block;
    width:100%;
    height: 100%;
}
		

.Fade{
    -webkit-animation: fade 1s;
            animation: fade 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
@-webkit-keyframes fade{
    from{opacity:100;}
    to{opacity: 0;}
}
@keyframes fade{
    from{opacity:100;}
    to{opacity: 0;}
}
.button{
    /*position: absolute;*/
    display:inline-block;
    padding: 0.4em 1em 0.5em 1em;
    font-size: calc(1em + 2vw);
    /*bottom:10vh;
    left:50%;
    transform: translate(-50%,-50%);*/
    /*border: 0.06625em solid #fff;
    background-color: transparent;
    color: #fff;
    border-radius: 0.1em;*/
    cursor: pointer;
    margin:1em auto 1em auto;
    width: 7em;
}

.buttonOff{
    display: none;
    position: absolute;
    padding: 0.4em 1em 0.5em 1em;
    font-size: calc(.5em + 2vw);
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    border: 0.06625em solid #fff;
    background-color: transparent;
    color: #fff;
    border-radius: 0.1em;
    cursor: pointer;
    margin:0;
}
.buttonOn{
    display:inline-block;
    opacity:0;
    -webkit-animation: display 2s;
            animation: display 2s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
} 
@-webkit-keyframes display{
from {opacity:0;}
to{opacity:100;}
} 
@keyframes display{
from {opacity:0;}
to{opacity:100;}
}

@media screen and (max-width: 400px){
    .button{

    }
}
.foot{
    position:relative;
    height: 100%;
    text-align:center;
}
.foot-text{
    display:inline-block;
    margin: 2em auto 1em auto;
    color:#000;
}
.foot-social{
    display:block;
    position:relative;
    max-width:16em;
    width:auto;
    height:3.7em;
    margin: 1em auto 5em auto;
}

.Face{
    display:block;
    float:left;
    width:1.5em;
    height:1.5em;
    margin: 0 0 0 0;
    border-radius: 0.2em;
    padding: 1em;
    line-height:1.5em;
    background-image: url(/static/media/Facebook.f4c36e59.svg);
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #1877f2;
    cursor:pointer;
}
 .Insta, .In, .Twit{
    display:block;
    float:left;
    width:1.5em;
    height:1.5em;
    margin: 0 auto 0 0.5em;
    /*border: 0.0625em solid #D1D1D1;*/
    border-radius: 0.2em;
    padding: 1em;
    line-height:1.5em;
    cursor:pointer;
}
.Insta{/*IMAGE*/
    background-image: url(/static/media/Instagram.dfc5fa53.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.In{/*IMAGE*/
    background-image: url(/static/media/LinkedIn.ff5a6eba.svg);
    background-color:#0a66c2;
    background-size:70%;
    background-repeat: no-repeat;
    background-position: 0.7em 50%;
}
.Twit{/*IMAGE*/
    background-color:#1da1f2;
    background-image: url(/static/media/Twitter.88248faf.svg);
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
}
/* CREDIT CARDS START */
.creditcards-wrapper{
    margin: 0 auto 3em auto;
    width:100%;
    min-height: 10em;
    margin: 0 0 0 0;
}
.creditcards-wrapper p{
    display:inline-block;
    width:100%;
    color:#000;

}

.creditcards-group{
    position:relative;
    margin: 1em 0 0 0;
    min-height: 4em;
}
.visa{
    display: inline-block;
    position:relative;
    height:4em;
    width:6em;
    background-color: #15195A;
    border-radius: 0.3em;
    
}
.visa-pic{
    position: absolute;
    width: 80%;
    top: 50%;
    left:50%;
    -webkit-transform:translate(-50%,-50%);
            transform:translate(-50%,-50%);
}

.amex,.master{
    position:relative;
    display: inline-block;
    height:4em;
    width:4em;
    overflow: hidden;
    margin: 0 0 0 0.5em;
}

.amex-pic{
    width:4em;
    border-radius: 0.2em;
}

.master-pic{
    position:absolute;
    width: 100%;
    bottom:0;
    left:0;
}

.discover{
    display: inline-block;
    position:relative;
    height:4em;
    width:6em;
    border: solid 0.15em #000;
    border-radius: 0.3em;
    overflow:hidden;
    margin: 0 0 0 0.5em;
}
.discover-pic{
    height: 4em;

}
/* CREDIT CARDS END */

.foot-logo{
    display: block;
    width:15em;
    height: 5em;
    margin:0 auto 0 auto;
}

.foot-link-wrap{
    display:block;
    width:100%;
    height:1.5em;
    padding: 0 0 0.5em 0;
}

.foot-copy{
    display:inline-block;
    margin: 0 0 0 0; 
}
.foot-copy > p{
  display:inline-block; 
}
.foot-copy #date,p{
    color:#D1D1D1;
}

.foot-links{
    display:inline-block;
    margin: 0 calc(0.2em + 1vw) 0 calc(0.2em + 1vw);
    color:#D1D1D1;
}
.foot-links:hover{
    color: #A7E1FF;
}
.foot-bottom-left{
    display:block;
    float:left;
    margin: 0 0 0 0;
    
}
.foot-bottom-left > p{
    display:inline-block;
}
.foot-bottom-right{
    display:block;
    float:right;
    margin: 0 0 0 0;
}
@media screen and (max-width:500px){
    .foot-links{
        display: block;
    }
    .foot-bottom-right{
        float:none;
        float:initial;
    }
    .foot-bottom-left{
        display:block;
        float:none;
        float:initial;
    }
    .foot-copy{
        display: inline-block;
        margin:0 0 0.8em 0;

    }
    .foot-logo{
        width:calc(6em + 30vw);
    }

}

.offers{
    position:relative;
    height:calc(100vh - 2em);
    text-align:center;
    margin:0;
    overflow:hidden;
    background: url(/static/media/offersBackground.cce69e9b.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    /*filter: blur(0.2em);*/
    /*background-color:#333333;*/
    
}
/*.offers-overlay{
    position:absolute;
    top:0;
    width:100%;
    height:100%;
    background-color: rgb(0,0,0);
    opacity:0.7;
    z-index:-1;  
}*/
.offers-background{
    position:absolute;
    top:0;
    height:calc(100vh - 2em);
    width:100%; 
    background: url(/static/media/offersBackground.b327f452.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    /*filter: blur(0.2em);*/
    z-index:1;
    
    /*filter:blur(0.2em);*/


}
.offers-text{
    /*position:absolute;*/
    position:absolute;
    display:block;
    max-height: 100%;
    width:80%;
    top: 50%;
    left: 50%;
    -webkit-transform:translate(-50%, -50%);
            transform:translate(-50%, -50%);
    /*margin: 0 auto 0 auto;*/
    padding: 0 0 1em 0;
    
    /*top:20%;
    transform:translateY(-20%);*/
    border-radius: 0.5em;
    text-align: center;
    z-index:2 !important;
    /*background-color: rgba(255,255,255, 0.5);*/
    background-color: rgba(255,255,255, 0.7);
}


.offers-title{
    display:block;
    color: #00bfa5;
    z-index:2;
    margin: 5vh 0 0 0;
    font-weight:bold;
    font-size: calc(0.6em + 2.5vw);
}
.offers-title sub{
    color: #00bfa5;
    margin: none;
    font-size: calc(0.2em + 0.5vw);
}
.offers-subtitle{
    color: #00bfa5;
    margin-top:0;
    z-index:2;
    font-weight:normal;
    font-weight:initial;
    font-size: calc(0.6em + .5vw);
}
.offers-subtitle-btn{
    display: inline-block;
    background-color: #00bfa5;
    color:#FFF;
    margin: 1em auto;
    padding: 1em;
    border-radius: 0.2em;
    line-height: 2em;
    cursor: pointer;
    font-weight: bold;
}
.offers-subtitle-btn:hover{
    background-color:#009c87;
    cursor: pointer;
}

.offers-endStatement{
    color:#00bfa5;
    font-weight:normal;
    font-weight:initial;
    margin: 0.5em 0 0 0;
    text-shadow: -0.0125em -0.0125em 0 #000,
    0.0125em -0.0125em 0 #000,
    -0.0125em 0.0125em 0 #000,
    0.0125em 0.0125em 0 #000;
}

.offers-cursive{
    font-style: italic;
}

.offers-bold{
    font-weight: bold;
}
.testimonials{
    position:relative;
    display:block;
    height:50vh;
    width:100%;
    padding:0;
    margin:0;
}
#LeftArrow{
    position: absolute;
    top:50%;
    left:5%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
     z-index: 6;
     cursor: pointer;
     padding: 2em;
    
}
.arrow{
    border: solid #000;
    border-width: 0 0.0625em 0.0625em 0 ;
    display:inline-block;
    padding:0.45em;
    border-color: #fff;
   
}
.left{
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.right{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}


#RightArrow{
    position: absolute;
    top:50%;
    right:5%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 5;
    cursor: pointer;
    padding: 2em;
}

#LeftArrow:hover,#RightArrow:hover{
    background-color: #66afe0;
    border-radius: 0.2em;
    
    
}
.testimonials-Wrapper1{
    position:relative;
    width:100%;
    height:100%;
    margin:0;
    padding:0;
    z-index: 5;
}
.testimonials-image1{
    display:inline-block;
    float:left;
    background-color:#000;
    width:40%;
    height:50vh;

}
.testimonials-image1-child{
    width:100%;
    height:100%;
    background-image: url(/static/media/Henry2.d28b58ed.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}
.testimonials-review1{
    display:block;
    float: right;
    text-align: center;
    background-color:#000;
    color:#fff;
    width:60%;
    height:100%;
    margin:0;
}

.testimonials-title{
    display:inline-block;
    color:#fff;
    margin: 1em 0 0 0;
}

.testimonials-review{
    display:inline-block;
    margin: 1em 0.8em 0 0.8em;
    color:#fff;
    font-style: italic;
    font-weight: 500;
    font-size: calc(0.4em + 1vw);
}

.testimonials-name{
    color:#fff;
    display:block;
    float:left;
    text-align:left;
    margin: 1.5em 0 0 1em;  
    font-style: italic;
    font-weight:600;
}


.testimonials-Wrapper2{
    position:absolute;
    display:block;
    top:0;
    z-index: 4;
    background-color:#000;
    width:100%;

}

.testimonials-image2{
    display:inline-block;
    float:left;
    background-color:#000;
    width:40%;
    height:50vh;

}
.testimonials-image2-child{
    width:100%;
    height:100%;
    background-image: url(/static/media/John2.62a9c268.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.testimonials-review2{
    display:block;
    float: right;
    text-align: center;
    background-color:#000;
    color:#fff;
    width:60%;
    height:100%;
    margin:0;
}


.testimonials-Wrapper3{
    position:absolute;
    display:block;
    top:0;
    z-index: 3;
    background-color:#000;
    width:100%;

}

.testimonials-image3{
    display:inline-block;
    float:left;
    background-color:#000;
    width:40%;
    height:50vh;

}
.testimonials-image3-child{
    width:100%;
    height:100%;
    background-image: url(/static/media/Roxy2.ac899c55.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}
.testimonials-review3{
    display:block;
    float: right;
    text-align: center;
    background-color:#000;
    color:#fff;
    width:60%;
    height:100%;
    margin:0;
}
.slideOutRAni{
    -webkit-animation: slideOutR 1s 1;
            animation: slideOutR 1s 1;
    -webkit-animation-fill-mode: forward;
            animation-fill-mode: forward;
}

.slideOutLAni{
    -webkit-animation: slideOutL 1s 1;
            animation: slideOutL 1s 1;
    -webkit-animation-fill-mode: forward;
            animation-fill-mode: forward;
}
@-webkit-keyframes slideOutR{
    98%{
        -webkit-transform: translateX(98%);
                transform: translateX(98%);
    }
    100%{
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
        opacity:0;

    }
}
@keyframes slideOutR{
    98%{
        -webkit-transform: translateX(98%);
                transform: translateX(98%);
    }
    100%{
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
        opacity:0;

    }
}

@-webkit-keyframes slideOutL{
    98%{
        -webkit-transform: translateX(-98%);
                transform: translateX(-98%);
    }
    100%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        opacity:0;

    }
}

@keyframes slideOutL{
    98%{
        -webkit-transform: translateX(-98%);
                transform: translateX(-98%);
    }
    100%{
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        opacity:0;

    }
}

@media screen and (max-height: 500px) {
    .testimonials-review{
        /*width:97%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: " . . . ";*/
        font-size: calc(0.4em + (1.5vh - 0.01vw));

    }
}

@media screen and (max-width: 500px){
    .testimonials{
        height:calc(100vh - 2em);
    }
    .testimonials-title{
        font-size: calc(1em + 0.5vw);
    }
    div .testimonials-image1{
        position:absolute;
        bottom:0;
        display:block;
        width:100%;
    }
    .testimonials-review1,.testimonials-review2 ,.testimonials-review3{
        top:0;
        width:100%;
        font-size: calc(0.8em + (1.5vh - 0.01vw));
        
    }
    .testimonials-Wrapper2, .testimonials-Wrapper3 {
        position:absolute;
        float:right;
        height:100%;
        top:0%;
    }
    div .testimonials-image2{
        position:absolute;
        display:inline-block;
        bottom:0;
        width:100%;
    }
    div .testimonials-image3{
        position:absolute;
        display:inline-block;
        bottom:0;
         background-position: center top;
        width:100%;
    }
    .testimonials-image3-child{
        background-position: center top;
    }
    .testimonials-review{
        font-size: calc(0.5em + 0.5vw);
    }
    .testimonials-name{
        font-size: calc(0.5em + 0.5vw);
        margin: 0.5em 0 0 1em;  
    }

}
.div-separator{
    display:inline-block;
    width:100%;
    height:2em;

}
.VIB{
    position: relative;
    display: inline-block;
    width:100%;
    min-height:100vh;
    text-align:center;
    /*overflow: hidden;*/
}

.VIB-title{
    font-size:calc(0.6em + 1vw);
}

.VIB-subtitle{
    font-size:calc(0.6em + 0.5vw);

}

.VIB-container{
    display: flex;
    width: 100%;
    min-height: 100vh;
    /*background-color:red;*/
    margin: 5% 0 0 0;
    align-content:center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;
}

.VIB-plan{ /*Mobile First*/
    position:relative;
    display: inline-block;
    max-width:23.78em;
    width:100%;
    /*height: 50em;*/
    min-height: 40em;
    background-color: #fbfbfb;
    border: 0.2em solid #f9f9f9;
    margin: 5em auto;
    border-radius: 0.2em;
    

}
.VIB-plan:hover{
    -webkit-animation: borderColors 1s linear 1;
            animation: borderColors 1s linear 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.VIB-plan:hover .VIB-plan-image{
    -webkit-animation: borderColorMedal 1s linear 1;
            animation: borderColorMedal 1s linear 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.VIB-plan-image{
    position: absolute;
    top:0;
    left:50%;
    display:block;
    max-width:8em;
    max-height: 8em;
    width:100%;
    height:100%;
    /*background-image:url("https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQiW9pX1DkUBapFUSfOKxU3dgA481sUfSkaWV8VdzGJj8Uxcpui");
    */border-radius:50%;
    border: 0.15em solid #D1D1D1;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}
.VIB-plan-background-orange{
    background-color: orange;
}
.VIB-plan-background-silver{
    background-color: lightgrey;
}
.VIB-plan-background-green{
    background-color: lightgreen;
}
.VIB-plan-medal-text{
    display:inline;
    font-size:3em;
    color: #FFF;
    vertical-align:middle;
    line-height: 2.5em;
}

.VIB-plan-content{
    position:relative;
    display:inline-block;
    width:80%;
    height:60%;
    margin: 6em auto 2em auto;
    /*border: 0.15em solid #D1D1D1;*/ 
    border-radius: 0.1em;
}
.VIB-plan-description{
    display:inline-block;
    list-style-type: none;
    width: 100%;
    height:70%;
    font-weight: 600;
    margin: 1em 0 2em 0;
}
.VIB-plan-description-item{
    /*color:#00d4fa;*/
    color: #000;
   
}

.VIB-plan-disclaimer{
    position:absolute;
     display:block;
     width:100%;
     bottom:0;
     color:#D1D1D1;
     
}

.VIB-plan-button{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    display: block;
    text-align:center;
    width: 50%;
    background-color: yellow;
    font-size: medium;
    font-size: initial;
    cursor: pointer;
    /*margin: 20% auto 0 auto;*/
    margin:0 auto;
    padding: 1em 0 1em 0;
    font-weight: bold;
    border-radius: 0.15em;
}
.Orange{
    color: orange !important;
}
.Silver{
    color: grey;
}
.Plus{
    font-weight: bold;
}
.VIB-plan-description-hosting{
    margin: 2em 0 0 0;
    color:#6747c7;
}
.Hostinger{
    width:50%;
}

@-webkit-keyframes borderColors{
    100%{border-color: #7ff0c3;}
}

@keyframes borderColors{
    100%{border-color: #7ff0c3;}
}

@-webkit-keyframes borderColorMedal{
    100%{
        border-color: #7ff0c3 #7ff0c3 #7ff0c3 #7ff0c3;
    
    }
}

@keyframes borderColorMedal{
    100%{
        border-color: #7ff0c3 #7ff0c3 #7ff0c3 #7ff0c3;
    
    }
}

@media screen and (min-width: 750px){
    .VIB-plan{
        margin: 5em 5% 5em 0;
    }
    .last{
    margin:5em 0 5em 0;
    }
}




.faq{
    margin:0;
    padding-bottom:2em;
    min-height: 100vh;
    width: 100%;
    text-align: center;
   background: linear-gradient(to right, #4b525a, #6a6f79, #575d66);
   /*background-color: #f3fafd;*/

}
.faq-banner{
    position:relative;
   
}
.faq-banner-image{
    margin: 2.5em 0 0 0;
    width:100%;
     
}

.faq-banner-title{
    position: absolute;
    top:50%;
    -webkit-transform:translateY(-50%);
            transform:translateY(-50%);
    color: #fff;
    font-size: 4em;
    width:100%;

}

.faq-question{
    color: #fff;
    margin: 1.5em auto auto;
    border-top: solid 0.0625em #fff;
    border-bottom: solid 0.0625em #fff;
    max-width: 80%;
    background-color: #fff;
    box-shadow: rgba(0,0,0, 0.3) 0em 0.4em 1em -0.2em;
    padding: 0.5em 1em;
    border-radius: 0.3em;
}

.faq-question-title{
    color: grey;
    margin: 0.5em 0 0.5em 0;
}

.faq-question-text{
    color: #000;
    margin: 0.5em 0 0.5em 0;
}

.faq-question-link{
    /*color: #ff6666;*/
    color: #5bc7ff;
}

.faq-question-link:hover{
    color: #ff6666;
    /*color: #A7E1FF;*/
}

.contacts{
    position:relative;
    display:flex;
    padding: 2.5em 0 0 0;
    height:calc(100vh - 2.5em);
    width:100%;
    background-color: #fff;
    
}
.contacts-left{
    position:relative;
    display: block;
    width:50%;
    height:100%;
    overflow:hidden;
    background-image: url(/static/media/contacts-men.969045b1.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.contacts-right{
    position:relative;
    display:block;
    width:50%;
    height:100%;
}
.contacts-form-outsider-wrap{
}
.contacts-form{
    position: absolute;
    top:50%;
    left:50%;
    width: 25.7em;
    height:35.75em;
    /*background-color:#6a6f79;*/
    /*background-color: #fff;*/
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    /*animation: showForm 2s 1 forwards ease-out;
    animation-delay: 2s;*/
    font-family: 'Poppins', sans-serif;
    text-align: center;
    z-index:3;
}
.contacts-form-wrapper{
    position: relative;
    display: inline-block;
    width:100%;
    height:100%;
}
.contacts-form-radioGroup{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 1em auto 2em auto;
}

.contacts-form-labelAndBtn{
    position: relative;
    display: inline-block;
}
.labelAndBtn_first{
    margin: 0 0 0 1em;
}

.labelAndBtn_last{
    margin: 0 1em 0 0;

}
.contacts-form-radioGroup input[type="radio"]{
    display: block;
    border-radius: 20%;
    -webkit-appearance: none;
    appearance: none;
    width: 1.5em;
    height:1.5em;
    margin: 0 auto 0 auto;
    border: solid 0.2em #d1d1d1;
    background-color: #fff;
    
}
.contacts-form-radioGroup input[type="radio"]:hover{
    border-color: #7BAEE8;
    cursor: pointer;
}
.contacts-form-radioGroup input[type="radio"]:checked{
    -webkit-animation: pushedBtn 500ms 1 forwards ease-out;
            animation: pushedBtn 500ms 1 forwards ease-out;
}

.contacts-radioBtn{
    display:inline-block;
    margin: 0 auto 0 auto;
}
.contacts-radioBtn-text{
    /*color:#fff;*/
    color: #bbb2b2;
    display: flex;
}
.contacts-radioBtn-text:hover{
    cursor: pointer;
}
.contacts-form-title{
    margin: 1em 0 2em 0;
    /*color:#fff;*/
    color: #bbb2b2;
    font-weight: 500;
}

.contacts-form-textInputs{
    width: 70%;
    padding: 1em;
    border: solid 0.0625em #d1d1d1;
    border-radius: 0.2em;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    color:#707070;
}

.contacts-form-textArea{
    width: 70%;
    height: 8em;
    margin: 1.5em 0 3em 0;
    padding: 1em;
    border: solid 0.0625em #d1d1d1;
    border-radius: 0.2em;
    resize: none;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    color:#707070;
}

.contacts-form-submitBtn{
    display: block;
    margin: 0 auto 0 auto;
    width: 70%;
    height: 2.5em;
    background-color: #7BAEE8;
    border-radius: 0.2em;
    color: #fff;
    border: solid 0.1em #7BAEE8;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5em;
    line-height: 2.4em;
    font-weight: bold;
    box-sizing: border-box;
}
.contacts-form-submitBtn:hover{
    background-color: #fff;
    color: #7BAEE8;
    border-color: #7BAEE8;
    cursor: pointer;
}
#other-info{
    display: none;
    color: #7BAEE8;
    text-align:left;
}
#other-info .contacts-phoneLine,#other-info .contacts-emailLine{
    line-height:2em;
    height:2em;
}
#other-info .address img, #other-info .contacts-phoneLine img,#other-info .contacts-emailLine img {
    display:block;
    color: #7BAEE8;
    height: 2em;
    width:2em;
    float:left;
}

#other-info p{
    display: inline;
    color: #7BAEE8;
    height: 2em;
    line-height: 2em;
    margin: 0.5em 0 0 0.5em;
}

#other-info b{
    color: #7BAEE8;
}
#other-info .address{
    display: block;
    margin: 0 0 0.5em 0;
    line-height: 2em;
}



@-webkit-keyframes pushedBtn{
    100%{
        border: solid 0.8em #7BAEE8;
    }
}



@keyframes pushedBtn{
    100%{
        border: solid 0.8em #7BAEE8;
    }
}

@-webkit-keyframes showForm{
    100%{ opacity: 100%;}
}

@keyframes showForm{
    100%{ opacity: 100%;}
}

@media screen and (max-width: 830px){
    .contacts-left{
        width: 100%;
    }
    .contacts-right{
        position: absolute;
        width:100%;
    }
    .contacts-form{
        width: 100%;
    }
    input[type="radio"]{
        background-color: rgba(255,255,255,0.8);
    }
    .contacts-form-textInputs, .contacts-form-textArea{
        background-color: rgba(255,255,255, 0.8);
    }

    .contacts-right .contacts-form-submitBtn{
        opacity:1;
    }
    #other-info{
        color:#fff;
        background-color: rgba(127,127,127,0.5);
        border-radius: 0.2em;
        margin: 1em;
        padding: 0.5em 0 0.5em 0;
    }
    #other-info img{
        color: #fff;
        -webkit-filter: contrast() brightness(1.5);
                filter: contrast() brightness(1.5);
    }
    #other-info p{
        color:#fff;
    }
    #other-info b{
        color:#fff;
        
    }
    #other-info .address, .contacts-phoneLine, .contacts-emailLine{
        margin: 0 0 0 1em;
        color:#fff;
    }
    
    .contacts-form-outsider-wrap{


    }
    
}
.privacy,.conditions{
    display:inline-block;
    margin: 2.5em 0 0 0;
    padding: 0 0 2.5em 0;
    width: 100%;
    min-height:calc(100vh - 2.5em);
    background-color: skyblue;
    
}

.privacy-banner{
    position: relative;
    margin: 0 0 2em 0;
    height: calc(100vh - 2.5em);
    width: 100%;
    background-color: #fff;
}
.privacy-banner-wrapper{
    position: absolute;
    display: block;
    top:50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    text-align: center;
}
.privacy-banner-image{
    width: 4em;
    margin: 0 0 1em 0;
}
.privacy-banner-text{
    display:block;
    opacity:0;
    margin: 0 auto 0 auto;
    -webkit-animation: textAppear 2s 1 ease-out forwards;
            animation: textAppear 2s 1 ease-out forwards;
    -webkit-animation-delay: 7s;
            animation-delay: 7s;
    color:#595959;
}
.privacy-wrapper,.conditions-wrapper{
    max-width: 90%;
    margin: 0 auto 0 auto;
    background-color: skyblue;
    padding: 0 0 1em 0;
    border-radius: 0.3em;
}

.privacy-subwrapper, .conditions-subwrapper{
    background-color: #fff;
    padding: 1em 0 1em 0;
    border-radius: 0.3em;
}

.privacy-title,.conditions-title{
    margin: 0 0 0 0.5em;
    color:#fff;
}
.privacy-index-title, .conditions-index-title{
    margin: 3em 0 0 0.6em;
}
.privacy-date,.conditions-date{
    color: #fff;
    margin: 1em 0 2.5em 1em;
    
}

.privacy-text,.conditions-text{
    text-align: justify;
    color:#595959;
    margin: 1em 1em 0 1em;
    background-color:#fff;
}

.privacy-text b,.conditions-text b{
    color:#595959;
}
.privacy-text u b,.conditions-text u b{
    color:#595959;
}

.privacy-text u, .conditions-text u{
    text-decoration: underline;
    color:#595959;
}
.privacy-links,.conditions-links{
    color: #85b4cc;
    cursor: pointer;
}
.privacy-links:hover,.conditions-links:hover{
    color: red;
}
.privacy-index-list,.conditions-index-list{
    display:inline-block;
    list-style: none;
}
.conditions-index-list-numbered{
    display:inline-block;
    margin: 0 0 0 2.5em;
}
.privacy-index-list a,.conditions-index-list a{
    display: inline-block;
    margin: 1em 0 0 1em;
    color: #66afe0;
    cursor:pointer;

}
.privacy-index-list a:hover, .conditions-index-list a:hover{
    color: red;
}
.anchor{
    padding-top:2.5em;
}

.privacy-item-title, .conditions-item-title{
    margin: 0 0 0 1em;
}

.privacy-item-subtitle, .conditions-item-subtitle{
    margin: 1.5em 0 0.5em 1em;
}

.privacy-list, .conditions-list{

}
.privacy-list li, .conditions-list li{
    list-style-type: '■';
    display: list-item;
    margin: 0 0 0 1.5em;
}
.conditions-list-bar li{
    list-style-type: '-';
    display: list-item;
    margin: 0 0 0 2em;
}

.italic{
    font-style: italic;
}

.privacy-list-item, .conditions-list-item{
    display: inline;
    width:1em;
    color:#595959;
}

.privacy-list-text, .conditions-list-text{
    display: list-item;
    padding:0 0 0 0.5em;
    width: 90%;
    text-align: justify;
    color:#595959;
    margin: 1em 0 0 0;
}
.privacy-list-text b, .conditions-list-text b{
    color:#595959;
}
.conditions-index-list-item{
    color:#595959;
    line-height: 2em;
}


.conditions-list .extra-margin-list{
    margin: 1em 0em 0 3em;
}

@-webkit-keyframes textAppear{
    100%{
        opacity: 1;
    }
}

@keyframes textAppear{
    100%{
        opacity: 1;
    }
}
.checkout{
    min-height: 100vh;
    width: 100%;
    display:block;
}

.checkout-wrapper-left{
    display:block;
    float:left;
    width:50%;
    height:100vh;
    background: linear-gradient(0.6turn, #866dff,#360da9ff);
    text-align: center;

}

.checkout-left-title{
    color: #fff;
}
.checkout-summary{
    display: block;
    text-align:left;
    max-width: 50%;
    margin: 0 auto 0 1em;
}

.checkout-summary-list{
    list-style: none; 
}
.checkout-summary-list-items{
    color: #fff;
    /*color: #00abff; */
}

.checkout-price{
    color: lime; 
}
.checkout-wrapper-right{
    position:relative;
    float:right;
    width:50%;
    height: 100vh;
    background-color: #fff;

}

.checkout-wrapper-right-title{
    display:block;
    text-align: center;
    margin: 1em;
    align-self: top;
}
.checkout-wrapper-inner-right{
    position:relative;
    display: flex;
    align-items: center;
    padding: 2em;
}
.checkout-wrapper-right-form{
    display: block;
    width: 80%;
    height: auto;
    margin: 0 auto 0 auto;
}


.checkout-form-card{
    position: relative;
    display: block;
    width: 15em;
    height: 8em;
    background: linear-gradient(0.4turn, #866dff,#360da9ff);
    /*background-color: blue;*/
    margin: 0 auto 0 auto;
    padding: 1em;
    border-radius: 0.4em;
    box-shadow: 0em 0em 0.3em;
}

.checkout-form-input{
    display: block;
    width: 90%;
     -webkit-appearance: none;
     -moz-appearance: textfield;
     padding: 0.5em;
     margin: 0.5em auto 0 auto;
     border-radius: 0.3em;
     background-color: rgba(255,255,255,0.2);
     color: #fff;
     font-weight: bold;
}

.checkout-form-hidden-input{
    display:none;
}

.checkout-form-input::-webkit-input-placeholder{
    font-weight: normal;
}

.checkout-form-input:-ms-input-placeholder{
    font-weight: normal;
}

.checkout-form-input::placeholder{
    font-weight: normal;
}

.checkout-form-input-ccv{
    float: left;
    width: 3em;
    margin: 0.5em 0 0 0.3em;
}
.checkout-form-cards-imgs{
    display: block;
    width: 5em;
    max-height: 3em;
    margin: 0.5em;
    float: right;
}

.checkout-form-price-wrapper{
    position: relative;
    display:inline-block;
    
}
.checkout-form-price-centering{
    position:relative;
    display: flex;
    width: 18.5em;
    margin: 0 auto 0 auto;
    align-items:center;
}
.checkout-wrapper-right-form input[type="radio"]{
    /*position:relative;*/
    display:block;
    -webkit-appearance: none;
    appearance: none;
    background-color: #d1d1d1;
    border: solid 0.2em #6040d6;
    border-radius: 0.4em;
    width: 10em;
    height: 8em;
    margin: 0.5em;

}

.checkout-wrapper-right-form input[type="radio"]:hover{
    background-color: #856cfe;
    cursor: pointer;
}
.checkout-wrapper-right-form input[type="radio"]:checked{
    background-color: #6040d6;
    color:#fff;
    border-color:#6040d6;
}
.checkout-form-price-input-label{
    position: absolute;
    text-align: center;
    top:50%;
    left: 50%;
    -webkit-transform:translate(-50%,-50%);
            transform:translate(-50%,-50%);
    color:#fff;
    font-weight: bold;
    cursor: pointer;
    
}


.checkout-links{
    display:block;
    position: absolute;
    width: 20%;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 1em;
    margin: 0 auto 0 auto;
    background-color:#6040d6;
    color: #fff;
    text-align: center;
    font-weight: 800;
    border-radius: 0.5em 0.5em 0 0 ;
    
}

/*.checkout-links:hover{
    color: #fff;
    background-color: #81ea81;
}*/

.checkout-form-button{
    display:block;
    padding: 1em;
    margin: 1em auto 0 auto;
    background-color:#66afe0;
    color: #fff;
    text-align: center;
    font-weight: 800;
}

.checkout-form-button:hover{
    cursor:pointer;
    background-color: #fff ;
    color: #66afe0;
    outline: solid 0.1em #66afe0;
}
.checkout-summary-title{
    color: #fff;
    margin: 0.5em;
}
.checkout-form-outside-input{
    display: block;
    margin: 0 auto 1em auto;
    padding: 0.8em;
    width: 15em;
    /*background-color: #d1d1d1;*/
    border: solid 0.1em #6040d6;
    border-radius: 0.3em;
    color: #6040d6;
    font-weight: bold;

}
.checkout-form-outside-input::-webkit-input-placeholder{
    color: #6040d6;
}
.checkout-form-outside-input:-ms-input-placeholder{
    color: #6040d6;
}
.checkout-form-outside-input::placeholder{
    color: #6040d6;
}

#required-wrapper{
    position: absolute;
    display:flex;
    margin: 0 auto 0 auto;
    bottom:0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    background-color: red;
    max-width: 80%;
    height: 5em;
    text-align:center;
    z-index: 3;
    
}
.required-text{
    font-weight: bold;
}
/*#checkout-submit{
    background-color: #6040d6;
}*/
.checkout-wrapper-right #checkout-submit :hover{
    background-color: #81ea81;
}
.checkout-links:hover{
    background-color: #81ea81;
}
.checkout-submit-red{
    background-color: red;
}
.checkout-submit-red:hover{
    background-color: red;
}

@media screen and (max-width:650px){
    .checkout-wrapper-left{
        display:inline-block;
        margin: 0;
        padding: 0;
        width:100%;
        height:50vh;
        overflow-y: scroll;
    }
    .checkout-wrapper-right{
        display:inline-block;
        margin: 0;
        padding: 0 0 11em 0;
        width:100%;
        height: auto;
        height: initial;
    }
    .checkout-links{
        position:absolute;
        bottom: 0;
        display:block;
        border-radius: 0.5em 0.5em 0 0;
        width: 50%;
        padding: 0.6em;
        margin:0;
    }
    
}


.Skype{
    position: fixed;
    display: block;
    bottom: 2.5em;
    left: 1.5em;
    z-index: 7;
    border-radius: 0.2em;
    overflow: hidden;
    color:#fff;
}
.skype-button{
    width: 5em;
    height: 3em;
}
.Skype .lwc-chat-button{
    background-color: #00aff0;
}
.Skype #chatIconText{
    color: #fff;
}
.lwc-button-icon{
    width:1.8em;
    height:1.8em;
    background-image: url(/static/media/skype-icon.9536b6b8.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.lwc-button-icon svg{
    display:none;
}

@media screen and (max-width: 500px){
    .Skype{
        width: 2.7em;
        height: 2em;
    }
    .lwc-chat-button{
        height: 2em;
    }
    .Skype #chatIconText{
        display:none;
    }
}
.whatsapp{
    position: fixed;
    display: block;
    width: 8.1em;
    height: 1.5em;
    bottom: 2.5em;
    left: 1.5em;
    z-index: 7;
    margin: 0 0 1em 0;
    /*border-radius: 0.2em;
    color:#fff;
    background-color: #00BFA5;
    padding: 0.4em 0.6em;
    line-height: 1.5em;*/
    
}
.whatsapp-button{
    display:block;
    width: 6.9em;
    height: 1.5em;
    border-radius: 0.2em;
    color:#fff;
    background-color: #00BFA5;
    padding: 0.4em 0.6em;
    line-height: 1.5em;

    /*display: block;
    float: right;
    height: 1.5em;
    width: 5em;
    line-height: 1.5em;
    color: #fff;
    text-align: center;*/
}

.whatsapp-text{
    display: block;
    float:right;
    color:#fff;
}
.whatsapp-img{
    display:inline-block;
    height:1.5em;
    width: 1.5em;
    line-height: 1.5em;
    color: #fff;
}

@media screen and (max-width: 500px){
    .whatsapp{
        width: 1.5em;
        height: 1.5em;
    }
    .whatsapp-button{
        width: 1.5em;
    }
    .whatsapp-text{
    display: none;
    }
}
/*.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
